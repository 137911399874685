<template>
	<div>
    <div class="dashboard__container pt-3">
      
      <Loader v-if="(!event || !event.id) || isLoading" />
     	<transition name="fade">
		    <div class="dashboard__container--body pt-3" style="justify-content: space-evenly;" v-if="event && event.jobs && event.jobs.length > 0">
		    	<div class="dashboard__container--body--col mb-0" style="width:100%;">

			    	<div class="list">
			    		<h4>Create New Shift - Type: {{eventInfo.shiftType || 'Gig'}}</h4>
			    		<!-- <span class="caption mb-3">*You can enter these shift defaults just as before, then override them per person when you place them if needed.</span> -->
			    		<div class="mb-3">
	    					<label for="shiftName">Shift Title (ex: Night Shift Janitor):</label>
	    					<input type="text" v-model.trim="newShift.title" id="shiftName" required />
	    				</div>
	  					<div class="flex flex-wrap">
	  						<div v-if="eventInfo && eventInfo.jobs && eventInfo.jobs.length >= 1"  class="pr-3 mb-3">
	  							<label for="day">Set Default Job for Shift:</label>
	  							<v-select
			              label="title" 
			              :options="eventInfo.jobs"
			              v-model="newShift.job"
			              style="min-width: 280px;"
			              >
			            </v-select>
			            <div class="mt-2 caption" v-if="newShift.job">
			          	Pay Rate: ${{newShift.job.rate}} | Bill Rate: ${{newShift.job.billRate}}
			          </div>
			          </div>
			          
	  						
	  						<div class="pr-3 mb-3">
	  							<label for="staff">Total Staff Requested:</label>
		            	<input type="number" v-model.trim="newShift.staffReqested" id="staff" />
	  						</div>
	  						<div class="pr-3 mb-3" v-if="(eventInfo && (!eventInfo.shiftType || eventInfo.shiftType == 'Gig') && (eventInfo.groups && eventInfo.groups.length >= 1))">
	  							<label for="notification">Send New Shift Notification to Group:</label>
	  							<div>
		            	<input type="checkbox" v-model.trim="newShift.sendNotification" id="notification" />
		            	</div>
	  						</div>
	  					</div>
	  					<!-- <div class="flex mt-3">
	  						<div>
		  						<label for="day">Select Shift Type:</label>
	  							<v-select
			              label="title" 
			              :options="types"
			              v-model="newShift.type"
			              style="min-width: 280px;"
			              >
			            </v-select>
		            </div>
	  					</div> -->
	  					<div class="mb-3" style="max-width:280px;">
		          	<label for="shiftPo">PO Number:</label>
		          	<input type="text" v-model.trim="newShift.poNumber" id="shiftPo">
		          </div>
	  					<div class="flex mt-3 flex-column" v-if="(eventInfo && (!eventInfo.shiftType || (eventInfo.shiftType == 'Event' || eventInfo.shiftType == 'Gig')))">
	  						<div class="flex mt-3">
		  						<button class="chipDark mr-2 mb-2" v-for="(item, index) in shiftDates" @click="removeDay(index)">{{item}} <i class="fa-regular fa-xmark"></i></button>
		  					</div>
		  					<div class="flex mb-3">
			  					<div v-if="event && event.days">
		  							<label for="day">Select day:</label>
				            <input type="date" v-model.trim="newShiftDate" id="pickDate" @input="onPickDate()" />
				          </div>
				        </div>
		  					<div class="flex mb-3">
		  						<div>
			            <label for="start">Default Start Time:</label>
				            <input type="time" v-model.trim="newShift.start" id="start" />
				          </div>
				          <div class="pl-3">
				            <label for="end">Default End Time:</label>
				            <input type="time" v-model.trim="newShift.end" id="end" />
				          </div>
		  					</div>
	  					</div>
	  					<div class="flex mt-3 flex-column" v-if="(eventInfo && (eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job')))">
	  						<div class="flex mt-3 flex-row">
		  						<div class="mr-5 flex align-center" style="width:80px;">
		  							<span class="caption strong">Start Date</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Date:</label>
				            <input type="date" v-model.trim="newShift.startDate" id="start" />
		  						</div>
		  					</div>
	  						<div class="flex mt-3 flex-row">
		  						<div class="mr-5 flex align-center" style="width:80px;">
		  							<span class="caption strong">Sunday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.sundayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.sundayEnd" id="end" />
		  						</div>
		  					</div>
	  						<div class="flex mt-3 flex-row">
		  						<div class="mr-5 flex align-center" style="width:80px;">
		  							<span class="caption strong">Monday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.mondayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.mondayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Tuesday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.tuesdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.tuesdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Wednesday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.wednesdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.wednesdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Thursday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.thursdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.thursdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Friday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.fridayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.fridayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Saturday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="newShift.saturdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="newShift.saturdayEnd" id="end" />
		  						</div>
		  					</div>
	  					</div>
	  					

	  					<!-- <div class="mb-3 mt-5" style="max-width: 300px;">
	              <label for="rep">Required Certifications:</label>

	              <v-select
	                class="mt-2"
	                :options="newShift.job.certs"
	                v-model="newShift.job.certs"
	                multiple
	                >
	              </v-select>
	  					</div> -->

	  					<div class="mb-3">
		            <label for="details">Shift-Specific Details or Job Description:</label>
		            <textarea name="details" id="details" cols="30" rows="3" v-model.lazy="newShift.details" style="background: #efefef;"></textarea>
		          </div>

		          <div class="mb-3">
		            <label for="info">Shift Into to Email Staff:</label>
		            <textarea name="info" id="info" cols="30" rows="1" v-model.lazy="newShift.info" style="background: #efefef;"></textarea>
		          </div>

            <div class="mb-3">
            	<h4>Attach Shift Files</h4>
              <label for="fileTitle">Details:</label>
              <input class="mb-2" placeholder="File Title" type="text" v-model.trim="fileTitle" id="fileTitle" />
              <textarea placeholder="File Description" name="fileDescription" id="fileDescription" cols="30" rows="1" v-model="fileDescription"></textarea>

              <input class="mt-3" type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage">
              <progress :value="uploadValue" max="100" v-if="showBar"></progress>
              <div class="mb-3">
                <button v-if="imageData != null" class="btn btn__primary mt-3" @click="onUploadFile">
                  Upload
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest3">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
              <div v-if="newShift.files && newShift.files.length >= 1">
                <vue-good-table
                  :columns="columns2"
                  :rows="newShift.files"
                  >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'url'">
                      <a :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                    </span>
                    <span v-else-if="props.column.field == 'extras'">
                      <button @click="deleteUploadedFile(props.row, props.index)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
              </div>
		        </div>
	          <div v-if="eventInfo.shiftType == 'Gig'">
	          	<button :disabled="!newShift.staffReqested || !newShift.job.rate" class="btn btn__success mt-2" @click="addNewShift()">Add Shift</button>
	          </div>
	          <div v-if="eventInfo.shiftType == 'Job'">
	          	<div v-if="!newShift || !newShift.job || newShift.job.rate">*You must set the pay rate for the job before adding the shift</div>
	          	<button :disabled="!newShift.staffReqested || !newShift.job.rate" class="btn btn__success mt-2" @click="addNewPermanentShift()">Add Shift</button>
	          </div>
					</div>
				</div>
			</div>
		  </transition>
		  <hr>
		  <div class="dashboard__container--body">
		  	<div style="width:100%; overflow: auto;">
	        <Loader v-if="!eventShifts || eventShifts.length == 0" />
	        <vue-good-table
	            :columns="columns"
	            :rows="eventShifts"
	             styleClass="vgt-table condensed"
	            :search-options="{
	              enabled: false,
	              placeholder: 'Search this table',
	            }"
	            :pagination-options="{
	              enabled: true,
	              mode: 'records',
	              perPage: 25,
	            }"
	            
	          >
	          <template slot="table-row" slot-scope="props">
	            <span v-if="props.column.field == 'created'">
	              <!-- <span v-if="props.row.created">{{formatDate(props.row.created)}}</span> -->
	            </span>
	            <span v-else-if="props.column.field == 'day'">
	            	<span v-if="props.row.type == 'Permanent' || props.row.type == 'Job'">
	            		
	            	</span>
	            	<span v-else>
	            		{{props.row.day | moment("MM/DD/YYYY")}}
	            	</span>
	            </span>
	            <span v-else-if="props.column.field == 'type'">
	              <span v-if="props.row.eventInfo && props.row.eventInfo.shiftType">{{props.row.eventInfo.shiftType}}</span>
	            </span>
	            <span v-else-if="props.column.field == 'visible'">
	              <i class="fa-solid fa-check" v-if="props.row.visible" style="color:green;"></i>
	            </span>
	            <span v-else-if="props.column.field == 'startTime'">
	            	{{formatAMPM(props.row.startTime)}}
	            </span>
	            <span v-else-if="props.column.field == 'endTime'">
	            	{{formatAMPM(props.row.endTime)}}
	            </span>
	        
	            <span v-else-if="props.column.field == 'edit'">
	            	<router-link :to="`/orders/` + eventInfo.id + `/shifts/` + props.row.id">
	            		<button class="btn btn__small btn__outlined">Open</button>
	            	</router-link>
	            </span>
	            <span v-else-if="props.column.field == 'link'">
	            	<router-link :to="`/orders/` + eventInfo.id + `/placement`">
	            		<button class="btn btn__small btn__primary">Placements</button>
	            	</router-link>
	            </span>

	            <span v-else-if="props.column.field == 'form'">
	            	<a :href="`https://jumpstaffing.com/form/` + props.row.id" target="_blank">
					          <button class="btn btn__small btn__outlined mr-3 mb-2">Web Form</button>
					        </a>
	            </span>



	            
	            <span v-else>
	              {{props.formattedRow[props.column.field]}}
	            </span>
	          </template>
	        </vue-good-table>
	       </div>
      </div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ShiftNote from '@/components/Placements/ShiftNote.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')
import * as moment from 'moment'

export default {
	props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'eventShifts',
  // props: ['eventInfo'],
  
  components: {
    Loader,
    ShiftNote,
    // DatePicker 
  },
  data() {
    return {
    	newShiftDate: "",
    	shiftDates: [],
      performingRequest: false,
      performingRequest3: false,
      isLoading: false,
      imageData: null,
	    fileTitle: '',
	    fileDescription: '',
	    uploadValue: 0,
	    showBar:false,
      showNewNote: false,
      newShift: {
      	files: [],
      	sendNotification: true
      },
      newName: '',
      types: ['Gig', 'Job'],
      // certs: ['Food Hander', 'Alcohol Awareness', 'Fork Lift', 'Scissor Lift'],
      columns: [
		      {
		        label: 'Day',
		        field: 'day',
		      },
		      {
		        label: '',
		        field: 'edit',
		        sortable: false,
		      },
		      {
		        label: '',
		        field: 'link',
		        sortable: false,
		      },
		      // {
		      //   label: 'Event',
		      //   field: 'event',
		      //   sortable: false,
		      // },
		      {
		        label: 'Title',
		        field: 'title',
		        width:'160px'
		      },
		      {
		        label: 'PO Number',
		        field: 'poNumber',
		        width:'80px'
		      },
		      {
		        label: 'Status',
		        field: 'status',
		        width:'80px'
		      },
		      {
		        label: 'Start',
		        field: 'startTime',
		        sortable: false,
		        width:'80px'
		      },
		      {
		        label: 'End',
		        field: 'endTime',
		        sortable: false,
		        width:'80px'
		      },
		      {
		        label: 'Type',
		        field: 'type',
		        sortable: false,
		        width:'60px'
		      },
		      // {
		      //   label: 'type',
		      //   field: 'workerType',
		      // },
		      {
		        label: 'Location',
		        field: 'location',
		        sortable: false,
		        width:'100px'
		      },
		      {
		        label: 'Code',
		        field: 'eventInfo.venue.shortCode',
		        sortable: false,
		        width:'60px'
		      },
		      {
		        label: 'Position',
		        field: 'position.title',
		        sortable: false,
		      },
		      {
		        label: 'Staff',
		        field: 'staff',
		        sortable: false,
		        width:'60px'
		      },
		      {
		        label: 'Form',
		        field: 'form',
		        sortable: false,
		        width:'120px'
		      },
		      // {
		      //   label: 'Start Time',
		      //   field: 'startTime',
		      // },
		      // {
		      //   label: 'End Time',
		      //   field: 'endTime',
		      // },
		  ],
		  columns2: [
	      {
	        label: 'Title',
	        field: 'title',
	      },
	      {
	        label: 'Description',
	        field: 'description',
	      },
	      {
	        label: 'Url',
	        field: 'url',
	        tdClass: 'text-right',
	      },
	      {
	        label: '',
	        field: 'extras',
	        tdClass: 'text-right',
	      },
	    ]
    }
  },
  created () {
  	this.$store.dispatch("getEventShifts", this.eventId)
    if (!this.certs || this.certs.length < 1) {
      this.$store.dispatch("getCerts")
    }
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'certs']),
    event() {
      return this.eventInfo
    },
    eventId() {
    	return this.eventInfo.id
    },
    groupIDs() {
    	let idsArray = []
    	this.event.groups.forEach(group => {
    		idsArray.push(group.id)
    	})
    	return idsArray
    }
  },
  methods: {
  	previewImage(event) {
      this.uploadValue=0;
      this.imageData=event.target.files[0]
    },
    onUploadFile() {
      this.showBar = true
      let newShift = this.newShift
      let fileTitle = this.fileTitle
      let fileDescription = this.fileDescription
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          // var docRef = fb.eventsCollection.doc(event.id)
          // docRef.update({
          //   files: fb.firestore.FieldValue.arrayUnion({
          //     title: fileTitle,
          //     description: fileDescription,
          //     url: downloadURL
          //   })
          // });
          newShift.files.push({
            title: fileTitle,
            description: fileDescription,
            url: downloadURL
          })
      	})
        this.showBar = false
      })
      this.imageData = null
      this.fileTitle = ''
      this.fileDescription = ''
      this.$refs.fileInputTip.value = null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  	removeDay(index) {
  		this.shiftDates.splice(index, 1);
  	},
  	onPickDate() {
  		this.shiftDates.push(this.newShiftDate)
  		this.newShiftDate = ''
  	},
  	// onRowClick(params) {
  	// 	let url = `/orders/` + this.$route.params.id + `/shifts/` + params.row.id
    //   router.push(url)
  	// },
  	// goBack() {
    //   router.go(-1)
    // },
    // updateJob() {
    //   console.log(event)
    //   let event = this.event
    //   this.$store.dispatch('updateEvent', event)
    // },
    // updateEvent(){
    //   let event = this.event
    //   console.log(event)
    //   this.$store.dispatch('updateEvent', event)
    // },
    // submitName() {
    // 	let event = this.event
    // 	this.$store.dispatch('updateEvent', event)
    // },
    addNewPermanentShift () {
  		let shift = {
        position: this.newShift.job || { title: null},
        // startTime: this.newShift.start || null,
        // endTime: this.newShift.end || null,
        sendNotification: false,
        files: this.newShift.files || [],
        name: this.newShift.job.title || null,
        title: this.newShift.title || null,
        poNumber: this.newShift.poNumber || null,
        certs: this.newShift.job.certs || null,
        // location: this.newShift.location || null,
        staff: this.newShift.staffReqested,
        eventId: this.event.id,
        event: this.event.title,
        eventStatus: this.event.status,
        eventInfo: this.event,
        minHours: this.event.minHours || 0,
        day: this.newShift.startDate,
        workerType: this.event.workerType || 'Contractor',
        groups: this.event.groups,
        groupIds: this.groupIDs,
        center: this.event.venue.center,
        location: this.event.venue.address.city + ', ' +  this.event.venue.address.state,
        venue: this.event.venue.title,
        venueId: this.event.venueId,
        address: this.event.venue.address,
        type: this.eventInfo.shiftType,
        sundayStart: this.newShift.sundayStart || null,
        mondayStart: this.newShift.mondayStart || null,
        tuesdayStart: this.newShift.tuesdayStart || null,
        wednesdayStart: this.newShift.wednesdayStart || null,
        thursdayStart: this.newShift.thursdayStart || null,
        fridayStart: this.newShift.fridayStart || null,
        saturdayStart: this.newShift.saturdayStart || null,
        sundayEnd: this.newShift.sundayEnd || null,
        mondayEnd: this.newShift.mondayEnd || null,
        tuesdayEnd: this.newShift.tuesdayEnd || null,
        wednesdayEnd: this.newShift.wednesdayEnd || null,
        thursdayEnd: this.newShift.thursdayEnd || null,
        fridayEnd: this.newShift.fridayEnd || null,
        saturdayEnd: this.newShift.saturdayEnd || null,
        status: 'Open'
      }
      console.log(shift)
      fb.shiftsCollection.add(shift)
      .then(
        doc => {
        	console.log(doc.id)
        	fb.shiftsCollection.doc(doc.id).update({
        		id: doc.id, 
        	})
        }
      )
      // this.$store.dispatch("getEventShifts", this.eventId)
      this.newShift = {}
      this.newShiftDate = ''
    },
    addNewShift () {
    	this.shiftDates.forEach(date => {
    		let shift = {
	        position: this.newShift.job || { title: null},
	        startTime: this.newShift.start || null,
	        endTime: this.newShift.end || null,
	        name: this.newShift.title || null,
	        title: this.newShift.title || null,
	        files: this.newShift.files || [],
	        certs: this.newShift.job.certs || null,
	        // location: this.newShift.location || null,
	        staff: this.newShift.staffReqested,
	        eventId: this.event.id,
	        event: this.event.title,
	        poNumber: this.newShift.poNumber || null,
	        eventInfo: this.event,
	        minHours: this.event.minHours || 0,
	        day: date,
	        workerType: this.event.workerType || 'Contractor',
	        groups: this.event.groups,
	        groupIds: this.groupIDs,
	        center: this.event.venue.center,
	        location: this.event.venue.address.city + ', ' +  this.event.venue.address.state,
	        venue: this.event.venue.title,
	        venueId: this.event.venueId,
	        address: this.event.venue.address,
	        type: 'Event',
	        eventSlug: this.event.slug,
	        eventTitle: this.event.title,
	        eventStatus: this.event.status,
	        status: 'Open'
	      }
	      console.log(shift)
	      fb.shiftsCollection.add(shift)
	      // .then(
	      //   doc => {
	      //   	fb.shiftsCollection.doc(doc.id).update({
	      //   		id: doc.id, 
	      //   	})
	      //   }
	      // )
    	})
      
      // this.$store.dispatch("getEventShifts", this.eventId)
      // this.$store.dispatch("updateEventDays", {
      // 	event: this.eventId,
      // 	day: this.newShift.day
      // })
      this.newShift = {}
      this.newShiftDate = ''
    },
    // updateShift(eventShift) {
    // 	this.performingRequest = true
    // 	eventShift.location = this.event.venue.address.city + ', ' +  this.event.venue.address.state;
    // 	eventShift.venue = this.event.venue.title;
    // 	eventShift.groupIds = this.groupIDs
    // 	this.$store.dispatch("updateEventShift", eventShift)
    // 	setTimeout(() => {
    //     this.performingRequest = false
    //   }, 1000)
    // },
    // showDeleteShift(eventShift) {
    // 	eventShift.showDelete = true
    // 	this.$store.dispatch("updateEventShift", eventShift)
    // },
    // cancelDeleteShift(eventShift) {
    // 	eventShift.showDelete = false
    // 	this.$store.dispatch("updateEventShift", eventShift)
    // },
    // deleteShift(eventShift) {
    //   console.log(eventShift)
    //   this.$store.dispatch("deleteShift", eventShift.id)
    // },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }
      return date;
    },
  },
  beforeDestroy () {
  	this.performingRequest = null
  	delete this.performingRequest
  	this.newShift = null
  	delete this.newShift
  	this.isLoading = null
  	delete this.isLoading
  	this.columns = null
  	delete this.columns
  	this.columns2 = null
  	delete this.columns2
  	this.fileDescription = null
  	delete this.fileDescription
  	this.fileTitle = null
  	delete this.fileTitle
  	this.imageData = null
  	delete this.imageData
  	this.performingRequest3 = null
  	delete this.performingRequest3
  	this.showBar = null
  	delete this.showBar
  	this.showNewNote = null
  	delete this.showNewNote
  	this.uploadValue = null
  	delete this.uploadValue
  	
  	this.newShiftDate = null
  	delete this.newShiftDate
  	this.shiftDates = null
  	delete this.shiftDates
  	this.newName = null
  	delete this.newName
  	this.types = null
  	delete this.types
  	delete this.formatAMPM
  	delete this.addNewShift
  	delete this.onPickDate
  	delete this.removeDay
  	this.$store.dispatch("getEventShifts", this.eventId)
  	// this.$store.dispatch("clearJobsState")
  	console.log(this)
  }
}
</script>
